import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as TrialAccountActions from "../../actions/trialAccountActions";
import { Helmet } from 'react-helmet';
import * as HELPER from "../../utils/helpers";
import { Link } from 'react-router-dom';
import { ButtonToolbar } from 'react-bootstrap';
import RadiologyService from "../../services/radiologyService";
import PathologyService from "../../services/pathologyService";
import PharmacyService from "../../services/pharmacyService";
import TrialAccountService from "../../services/trialAccountService";
import Notification from "../../utils/notification";
import moment from "moment";

class TrialAccountListPage extends React.Component {
    constructor(props) {
        super(props);

        this.props.paginate({});
    }

    columns = () => {
        return [
            {
                dataField: "location.address.address_line_1",
                text: "Address Line 1",
                hidden: true
            },
            {
                dataField: "location.address.city",
                text: "City",
                hidden: true
            },
            {
                dataField: "location.address.parish",
                text: "Parish",
                hidden: true
            },
            {
                dataField: "service_provider_id",
                text: "Provider Id",
                sort: false
            },
            {
                dataField: "user.user.full_name",
                text: "Doctor Name",
            },
            {
                dataField: "user.user.email",
                text: "Email",
            },
            {
                dataField: "address",
                text: "Address",
                isDummyField: true,
                formatter: this.addressFormatter
            },
            {
                dataField: "user.user.contact_number",
                text: "Contact No.",
            },
            {
                dataField: "status",
                text: "Status",
                sort: false,
                formatter: cell => {
                    return cell === 1 ? "Active" : "Inactive";
                }
            },
            {
                dataField: "days_remaining",
                text: "Days Remaning",
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    dateFormatter = (cell) => {
        return HELPER.getDateFormat(cell);
    };

    addressFormatter = (cell, row) => {
        return (
            <>
            {row.location.address.address_line_1 + ', ' + row.location.address.city + ', ' + row.location.address.parish}
            </>
        );
    }

    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {/* view account */}
            {HELPER.hasPermission(this.props.admin, ['trial-account-view']) && <Link className="btn btn-info" to={'/temp-doctor/view/' + row.service_provider_id}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
            {/* extend account */}
            <Link className="btn btn-danger" to={'/temp-doctor/account/view/' + row.service_provider_id}><i className="fa fa-exclamation"></i></Link>
        </ButtonToolbar>;
    }

    extendExpiry = (serviceProviderId) => {

        TrialAccountService.extendTrialAccountExpiry({ 'service_provider_id': serviceProviderId })
            .then(resp => {
                let data = resp.data;
                Notification.show('success', { message: data.message });
                this.setState({
                    reload: true
                });
                // this.props.history.push('/trial/account/view/'+serviceProviderId+'');
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show('success', { message: errorData.message });
                }
            });
    }

    checkTrailExpiry = (trialExpiryDate) => {
        const currentDate = moment();
        const expiryDate = moment(trialExpiryDate);
        const remainingTime = moment(expiryDate).diff(moment(currentDate), 'minutes');

        if (remainingTime <= 0) {
            return true;
        }

        return false;
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.paginate({ page, sizePerPage, sortField, sortOrder, searchText });
    };

    render() {
        let { data, meta } = this.props.user_account;

        console.log("PROPS", this.props);

        return (
            <div>
                <Helmet>
                    <title>Temp Doctor List</title>
                </Helmet>
                <Content title="Temp Doctor">
                    <Box>
                        <Box.Body>
                            <ToolkitProvider
                                search
                                keyField="id"
                                data={data}
                                columns={this.columns()}
                            >
                                {props => [
                                    <div className="pull-right">
                                        <Search.SearchBar {...props.searchProps} delay={1000} />
                                    </div>,
                                    <BootstrapTable
                                        {...props.baseProps}
                                        remote
                                        onTableChange={this.handleTableChange}
                                        pagination={paginationFactory({
                                            page: parseInt(meta.current_page),
                                            sizePerPage: parseInt(meta.per_page),
                                            totalSize: parseInt(meta.total)
                                        })}
                                    />
                                ]}
                            </ToolkitProvider>
                        </Box.Body>
                    </Box>
                </Content>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user_account: state.user_account,
    admin: state.auth.admin
});

const mapActionsToProps = {
    paginate: TrialAccountActions.tempDoctorList,
};

export default connect(mapStateToProps, mapActionsToProps)(TrialAccountListPage);