import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class TrialAccountService {
    static createTrialAccount(postData) {
        let paramsdata = JSON.stringify(postData);
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.post(apiRoute('/v1/trial/accounts'), paramsdata, requestOptions);
    }

    static list({ page, sizePerPage, sortBy, searchText }) { 
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText)
        };

        return axios.get(apiRoute('/v1/trial/accounts'), requestOptions);
    }

    static tempDoctorList({ page, sizePerPage, sortBy, searchText }) { 
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText)
        };

        return axios.get(apiRoute('/v1/nondhdoctors'), requestOptions);
    }

    static fetchTrialAccount(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/trial/accounts/' + id), requestOptions);
    }

    static extendTrialAccountExpiry(postData){
        let paramsdata = JSON.stringify(postData);
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.patch(apiRoute('/v1/extend/trial/account'), paramsdata, requestOptions);
    }

    static extendTempDoctorTrialAccountExpiry(postData){
        let paramsdata = JSON.stringify(postData);
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.patch(apiRoute('/v1/extend/temp-doctor/account'), paramsdata, requestOptions);
    }

}

export default TrialAccountService;