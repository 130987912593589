import React from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import PrivateLayout from './layouts/PrivateLayout';
import PublicLayout from './layouts/PublicLayout';
import LoginPage from './pages/auth/LoginPage';
import ForgetPasswordPage from './pages/auth/ForgetPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import NewPasswordPage from './pages/auth/NewPasswordPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import ListPatientPage from './pages/patient/ListPatientPage';
import ViewPatientPage from './pages/patient/ViewPatientPage';
import SearchPatientPage from './pages/patient/SearchPatientPage';
import SearchEmployeePage from './pages/Employee/SearchEmployeePage';
import EditPatientPage from './pages/patient/EditPatientPage';
import ListPharmacyPage from './pages/pharmacy/ListPharmacyPage';
import ListDoctorPage from './pages/doctor/ListDoctorPage';
import AddDoctorPage from './pages/doctor/AddDoctorPage';
import ViewDoctorPage from './pages/doctor/ViewDoctorPage';
import EditDoctorPage from './pages/doctor/EditDoctorPage';
import ViewPharmacyPage from './pages/pharmacy/ViewPharmacyPage';
import AddPharmacyPage from './pages/pharmacy/AddPharmacyPage';
import EditPharmacyPage from './pages/pharmacy/EditPharmacyPage';
import ListDrugPage from './pages/drug/ListDrugPage';
import AddDrugPage from './pages/drug/AddDrugPage';
import ViewDrugPage from './pages/drug/ViewDrugPage';
import EditDrugPage from './pages/drug/EditDrugPage';
import ListAdminUserPage from './pages/AdminUser/ListAdminUserPage';
import AddAdminUserPage from './pages/AdminUser/AddAdminUserPage';
import ViewAdminUserPage from './pages/AdminUser/ViewAdminUserPage';
import EditAdminUserPage from './pages/AdminUser/EditAdminUserPage';
import ListPrescriptionPage from "./pages/prescription/ListPrescriptionPage";
import ViewPrescriptionPage from "./pages/prescription/ViewPrescriptionPage";
import ListGenericDrugPage from './pages/drug/ListGenericDrugPage';
import AddGenericDrugPage from './pages/drug/AddGenericDrugPage';
import EditGenericDrugPage from './pages/drug/EditGenericDrugPage';
import ListCommissionPage from "./pages/user/ListCommissionPage";
import DrugPricePage from './pages/drug/DrugPricePage';
import ChangePasswordPage from './pages/AdminUser/ChangePasswordPage';
import ListCardPage from './pages/PatientCard/ListCardPage';
import RequestCardPage from './pages/PatientCard/RequestCardPage';
import AddRadiologistPage from "./pages/radiology/AddRadiologistPage";
import EditRadiologistPage from "./pages/radiology/EditRadiologistPage";
import ListRadiologistPage from "./pages/radiology/ListRadiologistPage";
import ViewRadiologistPage from "./pages/radiology/ViewRadiologistPage";
import AddPathologistPage from "./pages/pathology/AddPathologistPage";
import EditPathologistPage from "./pages/pathology/EditPathologistPage";
import ListPathologistPage from "./pages/pathology/ListPathologistPage";
import ViewPathologistPage from "./pages/pathology/ViewPathologistPage";
import ListPathologyTestsPage from "./pages/tests/ListPathologyTestsPage";
import AddPathologyTestPage from "./pages/tests/AddPathologyTestPage";
import ViewPathologyTestPage from "./pages/tests/ViewPathologyTestPage";
import EditPathologyTestPage from "./pages/tests/EditPathologyTestPage";
import PathologyTestPricePage from "./pages/tests/PathologyTestPricePage";
import ListRadiologyTestsPage from "./pages/tests/ListRadiologyTestsPage";
import AddRadiologyTestPage from "./pages/tests/AddRadiologyTestPage";
import ViewRadiologyTestPage from "./pages/tests/ViewRadiologyTestPage";
import EditRadiologyTestPage from "./pages/tests/EditRadiologyTestPage";
import RadiologyTestPricePage from "./pages/tests/RadiologyTestPricePage";
import RolesListPage from './pages/role_management/RolesListPage';
import RoleViewPage from './pages/role_management/ViewRolePage';
import RoleAddPage from './pages/role_management/AddRolePage';
import RoleEditPage from './pages/role_management/EditRolePage';
import ListOtherDrugsPage from './pages/others/ListOtherDrugsPage';
import ListOtherPathologyTestPage from './pages/others/ListOtherPathologyTestPage';
import ListOtherRadiologyTestPage from './pages/others/ListOtherRadiologyTestPage';
import ListOtherDoctorsPage from './pages/others/ListOtherDoctorsPage';
import PermissionDeniedPage from './pages/auth/PermissionDeniedPage';
import ListReIssueCardPage from './pages/card/ListReIssueCardPage';
import TrialAccountPage from './pages/TrialAccount/TrialAccountPage';
import TrialAccountListPage from './pages/TrialAccount/TrialAccountListPage';
import TempDoctorListPage from './pages/TempDoctor/TempDoctorListPage';
import TempDoctorAccountPage from './pages/TempDoctor/TempDoctorAccountPage';
import TempDoctorTrialAccountPage from './pages/TempDoctor/ViewTrialAccountPage';
import ViewTrialAccountPage from './pages/TrialAccount/ViewTrialAccountPage';
import ListUserAccountsPage from './pages/accounts/ListUserAccountsPage';
import UserAccountInfo from './pages/accounts/UserAccountInfo';
import ListCptPage from './pages/CPT/ListCptPage';
import AddCptPage from './pages/CPT/AddCptPage';
import ViewCptPage from './pages/CPT/ViewCptPage';
import ListIcdPage from './pages/ICD/ListIcdPage';
import AddIcdPage from './pages/ICD/AddIcdPage';
import ViewIcdPage from './pages/ICD/ViewIcdPage';
import Error404 from './pages/dashboard/Error404Page';
// import ServiceProviderOrderList from './pages/prescription/ListServiceProviderOrderPage';
import AddAmbulancePage from './pages/Ambulance/AddAmbulancePage';
import ViewAmbulancePage from './pages/Ambulance/ViewAmbulancePage';
import EditAmbulancePage from './pages/Ambulance/EditAmbulancePage';
import ListAmbulancePage from './pages/Ambulance/ListAmbulancePage';
import ListEmergencyHospital from './pages/EmergencyHospital/ListEmergencyHospital';
import ViewEmergencyHospital from './pages/EmergencyHospital/ViewEmergencyHospital';
import AddEmergencyHospitalPage from './pages/EmergencyHospital/AddEmergencyHospitalPage';
import EditEmergencyHospitalPage from './pages/EmergencyHospital/EditEmergencyHospitalPage';
import ListCovidCenterPage from './pages/CovidCenter/ListPage';
import AddCovidCenterPage from './pages/CovidCenter/AddPage';
import ViewCovidCenterPage from './pages/CovidCenter/ViewPage';
import EditCovidCenterPage from './pages/CovidCenter/EditPage';
import AddAirlinePage from './pages/Airline/AddPage';
import ListAirlinePage from './pages/Airline/ListPage';
import ViewAirlinePage from './pages/Airline/ViewPage';
import EditAirlinePage from './pages/Airline/EditPage';
import RequestPage from './pages/PatientAlias/RequestPage';
import PatientAliasViewPatient from './pages/PatientAlias/ViewPatientPage';
import ViewPatientPastRecord from './pages/PatientAlias/ViewPatientPastRecordPage';
import LogoManagementPage from './pages/others/LogoManangementPage';
import CovidDashboardPage from './pages/CovidDashboard/CovidDashboardPage';
import AuthorityAccessDashboardPage from './pages/AuthorityAccessDashboard/AuthorityAccessDashboardPage';
import ActivityLogPage from './pages/ActivityLog/ActivityLogPage';
import ListAssociateDoctorPage from './pages/associate-doctor/ListAssociateDoctorPage';
import ReferralReportList from './pages/lims/referralReportList';
import ReferralReportView from './pages/lims/referralReportView';
import TestResultReport from './pages/lims/testResultReport';
import ListOtherOrderPathologyTestPage from './pages/others/ListOtherOrderPathologyTestPage';
import ListOtherOrderRadiologyTestPage from './pages/others/ListOtherOrderRadiologyTestPage';
import ListPathologyLimsTestsPage from './pages/tests/ListPathologyLimsTestsPage';
import AddPathologyLimsTestPage from "./pages/tests/AddPathologyLimsTestPage";
import EditPathologyLimsTestPage from "./pages/tests/EditPathologyLimsTestPage";
import AddLimsSubTestPage from './pages/tests/AddLimsSubTestPage';

import ListPathologyLimsCategorysPage from './pages/tests/ListPathologyLimsCategorysPage';
import AddPathologyLimsCategoryPage from "./pages/tests/AddPathologyLimsCategoryPage";
import EditPathologyLimsCategoryPage from "./pages/tests/EditPathologyLimsCategoryPage";

import ListNewCpmmunityPage from './pages/communities/ListNewCommunitiesPage';


function App({ history, checked }) {
  return (
    <ConnectedRouter history={history}>
      {checked && <Switch>
        <Redirect exact from="/" to="/login" />
        <PublicLayout path="/login" exact component={LoginPage} />
        <PublicLayout path="/forget-password" exact component={ForgetPasswordPage} />
        <PublicLayout path="/reset-password" exact component={ResetPasswordPage} />
        <PublicLayout path="/new-password" exact component={NewPasswordPage} />
        <PrivateLayout path="/changepassword" exact component={ChangePasswordPage} />
        <PrivateLayout path="/logo-management" exact component={LogoManagementPage} allowRoles={["super-admin"]} />
        {/* patient alias */}
        <PrivateLayout path="/patient-requests" exact component={RequestPage} />
        <PrivateLayout path="/patients/:patientId" exact component={PatientAliasViewPatient} /> <PrivateLayout path="/patients/:patientId/records/:recordId" exact component={ViewPatientPastRecord} />
        <PrivateLayout path="/activity-logs/:service_provider_type" exact component={ActivityLogPage} allowRoles={["super-admin", "admin"]} />
        {/* Lims Referral Page */}
        <PrivateLayout path="/referral-report" exact component={ReferralReportList} />
        <PrivateLayout path="/referral-report/:id" exact component={ReferralReportView} />
        <PrivateLayout path="/test-result-report" exact component={TestResultReport} />
        {/* Covid Center module routes */}
        <PrivateLayout path="/covid-centers" exact component={ListCovidCenterPage} allowPermissions={['covid-center-list']} />
        <PrivateLayout path="/covid-centers/add" exact component={AddCovidCenterPage} allowPermissions={['covid-center-add']} />
        <PrivateLayout path="/covid-centers/:serviceProviderId" exact component={ViewCovidCenterPage} allowPermissions={['covid-center-view']} />
        <PrivateLayout path="/covid-centers/:serviceProviderId/edit" exact component={EditCovidCenterPage} allowPermissions={['covid-center-edit']} />
        {/* Airline routes */}
        <PrivateLayout path="/authority-access/add" exact component={AddAirlinePage} allowPermissions={['airline-add']} />
        <PrivateLayout path="/authority-access" exact component={ListAirlinePage} allowPermissions={['airline-list']} />
        <PrivateLayout path="/authority-access/:serviceProviderId" exact component={ViewAirlinePage} allowPermissions={['airline-view']} />
        <PrivateLayout path="/authority-access/:serviceProviderId/edit" exact component={EditAirlinePage} allowPermissions={['airline-edit']} />
        {/* Ambulance Portal Routes start*/}
        <PrivateLayout path="/ambulances/add" exact component={AddAmbulancePage} allowPermissions={['ambulance-add']} />
        <PrivateLayout path="/ambulances/:serviceProviderId" exact component={ViewAmbulancePage} allowPermissions={['ambulance-view']} />
        <PrivateLayout path="/ambulances/:serviceProviderId/edit" exact component={EditAmbulancePage} allowPermissions={['ambulance-edit']} />
        <PrivateLayout path="/ambulances" exact component={ListAmbulancePage} allowPermissions={['ambulance-list']} />
        {/* Ambulance Portal Routes end*/}
        {/* Hospital Portal Routes start*/}
        <PrivateLayout path="/hospitals/add" exact component={AddEmergencyHospitalPage} allowPermissions={['hospital-add']} />
        <PrivateLayout path="/hospitals/:serviceProviderId" exact component={ViewEmergencyHospital} allowPermissions={['hospital-view']} />
        <PrivateLayout path="/hospitals/:serviceProviderId/edit" exact component={EditEmergencyHospitalPage} allowPermissions={['hospital-edit']} />
        <PrivateLayout path="/hospitals" exact component={ListEmergencyHospital} allowPermissions={['hospital-list']} />
        {/* Ambulance Portal Routes end*/}

        <PrivateLayout path="/dashboard" exact component={DashboardPage} />
        <PrivateLayout path="/covid-dashboard" exact component={CovidDashboardPage} />
        <PrivateLayout path="/authority-access-dashboard" exact component={AuthorityAccessDashboardPage} />
        <PrivateLayout path="/patients" exact component={ListPatientPage} allowPermissions={['patient-list']} />
        <PrivateLayout path="/patient/search" exact component={SearchPatientPage} allowPermissions={['patient-search']} />
        <PrivateLayout path="/employee/search" exact component={SearchEmployeePage} allowPermissions={['patient-search']} />
        <PrivateLayout path="/patient/:patientId" exact component={ViewPatientPage} allowPermissions={['patient-view', 'patient-edit']} />
        <PrivateLayout path="/patient/:patientId/edit" exact component={EditPatientPage} allowPermissions={['patient-edit']} />
        <PrivateLayout path="/pharmacies" exact component={ListPharmacyPage} allowPermissions={['pharmacy-list']} />
        <PrivateLayout path="/pharmacy/add" exact component={AddPharmacyPage} allowPermissions={['pharmacy-add']} />
        <PrivateLayout path="/pharmacies/:serviceProviderId" exact component={ViewPharmacyPage} allowPermissions={['pharmacy-view']} />
        <PrivateLayout path="/pharmacies/:serviceProviderId/:type" exact component={EditPharmacyPage} allowPermissions={['pharmacy-edit']} />
        <PrivateLayout path="/doctors" exact component={ListDoctorPage} allowPermissions={['doctor-list']} />
        <PrivateLayout path="/doctors/add" exact component={AddDoctorPage} allowPermissions={['doctor-add']} />
        <PrivateLayout path="/doctors/:serviceProviderId" exact component={ViewDoctorPage} allowPermissions={['doctor-view']} />
        <PrivateLayout path="/doctors/:serviceProviderId/:type" exact component={EditDoctorPage} allowPermissions={['doctor-edit']} />
        <PrivateLayout path="/drugs" exact component={ListDrugPage} allowPermissions={['products-list']} />
        <PrivateLayout path="/drugs/add" exact component={AddDrugPage} allowPermissions={['products-add']} />
        <PrivateLayout path="/drugs/:id" exact component={ViewDrugPage} allowPermissions={['products-view']} />
        <PrivateLayout path="/drugs/:id/:type" exact component={EditDrugPage} allowPermissions={['products-edit']} />
        <PrivateLayout path="/admins" exact component={ListAdminUserPage} allowPermissions={['admin-list']} />
        <PrivateLayout path="/admins/add" exact component={AddAdminUserPage} allowPermissions={['admin-add']} />
        <PrivateLayout path="/admin/:id" exact component={ViewAdminUserPage} allowPermissions={['admin-view']} />
        <PrivateLayout path="/communities" exact component={ListNewCpmmunityPage}/>
        <PrivateLayout path="/admin/:id/:type" exact component={EditAdminUserPage} allowPermissions={['admin-edit']} />
        <PrivateLayout path="/prescriptions" exact component={ListPrescriptionPage} allowPermissions={['prescriptions-list']} />
        <PrivateLayout path="/prescriptions/:id" exact component={ViewPrescriptionPage} allowPermissions={['prescriptions-view']} />
        <PrivateLayout path="/genericdrugs" exact component={ListGenericDrugPage} allowPermissions={['generic-drugs-list']} />
        <PrivateLayout path="/genericdrug/add" exact component={AddGenericDrugPage} allowPermissions={['generic-drugs-add']} />
        <PrivateLayout path="/genericdrug/:id/:type" exact component={EditGenericDrugPage} allowPermissions={['generic-drugs-edit']} />
        <PrivateLayout path="/drug-price" exact component={DrugPricePage} allowPermissions={['drug-price-list']} />
        <PrivateLayout path="/patients/cards" exact component={ListCardPage} allowPermissions={['patient-card-list']} />
        <PrivateLayout path="/patients/cards/request" exact component={RequestCardPage} allowPermissions={['request-list']} />
        <PrivateLayout path="/commissions" exact component={ListCommissionPage} allowPermissions={['commissions-list']} />
        <PrivateLayout path="/radiologies" exact component={ListRadiologistPage} allowPermissions={['radiology-list']} />
        <PrivateLayout path="/radiology/add" exact component={AddRadiologistPage} allowPermissions={['radiology-add']} />
        <PrivateLayout path="/radiologies/:serviceProviderId/:type" exact component={EditRadiologistPage} allowPermissions={['radiology-edit']} />
        <PrivateLayout path="/radiologies/:serviceProviderId" exact component={ViewRadiologistPage} allowPermissions={['radiology-view']} />
        <PrivateLayout path="/pathologies" exact component={ListPathologistPage} allowPermissions={['pathology-list']} />
        <PrivateLayout path="/pathology/add" exact component={AddPathologistPage} allowPermissions={['pathology-add']} />
        <PrivateLayout path="/pathologies/:serviceProviderId/:type" exact component={EditPathologistPage} allowPermissions={['pathology-edit']} />
        <PrivateLayout path="/pathologies/:serviceProviderId" exact component={ViewPathologistPage} allowPermissions={['pathology-view']} />
        <PrivateLayout path="/pathology/tests" exact component={ListPathologyTestsPage} allowPermissions={['pathology-tests-list']} />
        <PrivateLayout path="/pathology/test/add" exact component={AddPathologyTestPage} allowPermissions={['pathology-tests-add']} />
        <PrivateLayout path="/pathology/test/:id" exact component={ViewPathologyTestPage} allowPermissions={['pathology-tests-view']} />
        <PrivateLayout path="/pathology/test/:id/:type" exact component={EditPathologyTestPage} allowPermissions={['pathology-tests-edit']} />
        <PrivateLayout path="/pathologytest/price" exact component={PathologyTestPricePage} allowPermissions={['pathology-test-price-list']} />
        
        <PrivateLayout path="/lims-tests" exact component={ListPathologyLimsTestsPage} allowPermissions={['pathology-lims-test-list']} />
        <PrivateLayout path="/lims-tests/add" exact component={AddPathologyLimsTestPage} allowPermissions={['pathology-lims-test-add']} />
        <PrivateLayout path="/lims-tests/:id/edit" exact component={EditPathologyLimsTestPage} allowPermissions={['pathology-lims-test-edit']} />
        <PrivateLayout path="/lims-tests/:id/add-test" exact component={AddLimsSubTestPage} allowPermissions={['pathology-lims-test-edit']} />

        <PrivateLayout path="/lims-categories" exact component={ListPathologyLimsCategorysPage} allowPermissions={['pathology-lims-category-list']} />
        <PrivateLayout path="/lims-categories/add" exact component={AddPathologyLimsCategoryPage} allowPermissions={['pathology-lims-category-add']} />
        <PrivateLayout path="/lims-categories/:id/edit" exact component={EditPathologyLimsCategoryPage} allowPermissions={['pathology-lims-category-edit']} />

        <PrivateLayout path="/radiology/tests" exact component={ListRadiologyTestsPage} allowPermissions={['radiology-tests-list']} />
        <PrivateLayout path="/radiology/test/add" exact component={AddRadiologyTestPage} allowPermissions={['radiology-tests-add']} />
        <PrivateLayout path="/radiology/test/:id" exact component={ViewRadiologyTestPage} allowPermissions={['radiology-tests-view']} />
        <PrivateLayout path="/radiology/test/:id/:type" exact component={EditRadiologyTestPage} allowPermissions={['radiology-tests-edit']} />
        <PrivateLayout path="/radiologytest/price" exact component={RadiologyTestPricePage} allowPermissions={['radiology-test-price-list']} />
        <PrivateLayout path="/roles" exact component={RolesListPage} allowPermissions={['role-list']} />
        <PrivateLayout path="/roles/add" exact component={RoleAddPage} allowPermissions={['role-add']} />
        <PrivateLayout path="/roles/:id" exact component={RoleViewPage} allowPermissions={['role-view']} />
        <PrivateLayout path="/roles/:id/edit" exact component={RoleEditPage} allowPermissions={['role-edit']} />
        <PrivateLayout path="/other-drugs" exact component={ListOtherDrugsPage} allowPermissions={['other-drugs-list']} />
        <PrivateLayout path="/other-pathology-test" exact component={ListOtherPathologyTestPage} allowPermissions={['other-pathology-tests-list']} />
        <PrivateLayout path="/other-radiology-test" exact component={ListOtherRadiologyTestPage} allowPermissions={['other-radiology-tests-list']} />
        <PrivateLayout path="/other-order-pathology-test" exact component={ListOtherOrderPathologyTestPage} allowPermissions={['other-order-pathology-tests-list']} />
        <PrivateLayout path="/other-order-radiology-test" exact component={ListOtherOrderRadiologyTestPage} allowPermissions={['other-order-radiology-tests-list']} />
        <PrivateLayout path="/other-doctors" exact component={ListOtherDoctorsPage} allowPermissions={['other-doctors-list']} />
        <PrivateLayout path="/reissue-card-requests" exact component={ListReIssueCardPage} allowPermissions={['duplicate-request-list', 'duplicate-request-action']} />
        <PrivateLayout path="/trial/accounts/add" exact component={TrialAccountPage} allowPermissions={['trial-account-create']} />
        <PrivateLayout path="/trial/accounts" exact component={TrialAccountListPage} allowPermissions={['trial-account-list', 'trial-account-edit', 'trial-account-view', 'trial-account-extend', 'trial-account-send-email']} />
        <PrivateLayout path="/temp-doctor/list" exact component={TempDoctorListPage} allowPermissions={['trial-account-list', 'trial-account-edit', 'trial-account-view', 'trial-account-extend', 'trial-account-send-email']} />
        <PrivateLayout path="/temp-doctor/view/:id" exact component={TempDoctorAccountPage} allowPermissions={['trial-account-view', 'trial-account-edit']} />
        <PrivateLayout path="/temp-doctor/account/view/:id" exact component={TempDoctorTrialAccountPage} allowPermissions={['trial-account-view', 'trial-account-edit']} />
        <PrivateLayout path="/trial/account/view/:id" exact component={ViewTrialAccountPage} allowPermissions={['trial-account-view', 'trial-account-edit']} />
        <PrivateLayout path="/service-providers/accounts" exact component={ListUserAccountsPage} allowPermissions={['billing-and-account']} />
        <PrivateLayout path="/service-providers/:id/accounts" exact component={UserAccountInfo} allowPermissions={['billing-and-account']} />
        <PrivateLayout path="/cpts" exact component={ListCptPage} allowPermissions={['icd-cpt-view']} />
        <PrivateLayout path="/cpts/add" exact component={AddCptPage} allowPermissions={['icd-cpt-create']} />
        <PrivateLayout path="/cpts/:code" exact component={ViewCptPage} allowPermissions={['icd-cpt-view']} />
        <PrivateLayout path="/icds" exact component={ListIcdPage} allowPermissions={['icd-cpt-view']} />
        <PrivateLayout path="/icds/add" exact component={AddIcdPage} allowPermissions={['icd-cpt-create']} />
        <PrivateLayout path="/icds/:code" exact component={ViewIcdPage} allowPermissions={['icd-cpt-view']} />
        <PrivateLayout path="/associate-doctors" exact component={ListAssociateDoctorPage} allowPermissions={['associate-doctor-list']} />
        {/* <PrivateLayout path="/service-providers/orders" exact component={ServiceProviderOrderList} allowPermissions={['pharmacies-order-list']} /> */}
        <PrivateLayout path="/unauthorized" exact component={PermissionDeniedPage} />
        <PrivateLayout component={Error404} />
      </Switch>}
    </ConnectedRouter>
  );
}

const mapStateToProps = state => ({
  checked: state.auth.checked,
})

export default connect(mapStateToProps, null)(App);
