import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Radio, Button, ButtonToolbar, Well } from 'react-bootstrap';
import * as TrialAccountActions from '../../actions/trialAccountActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import * as CONFIG from "../../utils/config";
import Alert from '../../components/Alert';
import _ from "lodash";
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import CommunityService from '../../services/communityService';

class TrialAccountPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            service_provider_type: "",
            service_provider_name: "",
            name_title: "",
            first_name: "",
            last_name: "",
            user_role_slug: "",
            email: "",
            license_number: "",
            location_name: "",
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            parish: "",
            city: "",
            pincode: "",
            user_gender: "",
            user_degree: "",
            user_specialization: "",
            contact_number: "",
            max_users: "",
            practice_name: "",
            practice_address: "",
            date_of_birth: "",
            communities: [],
            selectedParisCommunity: []
        };


        this.validator = new SimpleReactValidator();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.getCommunity()
    }

    getCommunity = () => {
        CommunityService.list().then(resp => {
            this.setState({
                communities: resp.data.data,
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        const numericRegex = /^[0-9]*$/;
        const charRegex = /^[A-Z || a-z]*$/;

        if (name === "service_provider_type") {
            let role = "";
            if (value === "pharmacy") {
                role = "pharmacist";
            }
            if (value === "radiology") {
                role = "radiologist";
            }
            if (value === "pathology") {
                role = "pathologist";
            }
            if (value === "nondhdoctor") {
                role = "nondhdoctor";
            }

            this.setState({
                [name]: value,
                user_role_slug: role
            });

            return;
        } else if (name === "contact_number") {
            if (numericRegex.test(value)) {
                this.setState({
                    [name]: value,
                });
            }
        } else if (name === "first_name" || name === "last_name") {
            if (charRegex.test(value)) {
                this.setState({
                    [name]: value,
                });
            }
        } else if (name === "parish") {
            this.setSelectedParisCommunity(value)
            this.setCommunity(null)
            this.setState({
                [name]: value
            });
        }
        else {
            this.setState({
                [name]: value
            });
        }

    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        const postData = {
            service_provider_type: this.state.service_provider_type,
            service_provider_name: this.state.service_provider_name,
            created_by_admin: this.props.admin.admin_id,
            service_provider_user: {
                name_title: this.state.name_title,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                license_number: this.state.license_number,
                user_role_slug: this.state.user_role_slug,
                user_gender: this.state.user_gender,
                user_degree: this.state.user_degree,
                user_specialization: this.state.user_specialization,
                contact_number: this.state.contact_number,
                max_users: this.state.max_users,
                practice_name: this.state.practice_name,
                practice_address: this.state.practice_address,
                service_provider_type: this.state.service_provider_type,
                date_of_birth: this.state.date_of_birth,
            },
            locations: {
                address: {
                    address_line_1: this.state.address_line_1,
                    address_line_2: this.state.address_line_2,
                    address_line_3: this.state.address_line_3,
                    city: this.state.city,
                    pincode: this.state.pincode,
                    parish: this.state.parish
                },
                name: this.state.location_name,
                max_profile_allowed: 1
            },
        }; console.log(postData);
        this.props.createTrialAccount(postData, this.props.history, this.props.admin);
        this.resetForm();
    }

    resetForm = () => {
        this.setState({
            service_provider_type: "",
            service_provider_name: "",
            name_title: "",
            first_name: "",
            last_name: "",
            user_role_slug: "",
            email: "",
            license_number: "",
            location_name: "",
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            parish: "",
            city: "",
            pincode: "",
            user_gender: "",
            user_degree: "",
            user_specialization: "",
            contact_number: "",
            max_users: "",
            practice_name: "",
            practice_address: "",
            date_of_birth: "",
        });
    }

    setSelectedParisCommunity = (parish) => {
        if (parish) {
            let selectedCommunities = _.filter(this.state.communities, (items) => items.parish_name == parish)
            selectedCommunities = selectedCommunities.map((items) => {
                return { 'label': items.community_name, 'value': items.community_name };
            })
            this.setState({
                selectedParisCommunity: selectedCommunities
            });
        } else {
            this.setState({
                selectedParisCommunity: []
            });
        }
    }

    setCommunity = (community) => {
        this.setState({ city: community ? community.value : '' });
    }

    createCommunity = (community) => {
        let selectedParisCommunity = this.state.selectedParisCommunity;
        selectedParisCommunity.push({ label: community, value: community });
        this.setState({ city: community, selectedParisCommunity: selectedParisCommunity });
    }

    render() {
        this.validator.purgeFields();
        return <div>
            <Helmet>
                <title>Add Trial Account</title>
            </Helmet>
            <Content title="Add Trial Account">

                <Box>
                    {/* <Box.Head title="Add Trial Account"><a href="/Pharmacies" className="btn btn-info pull-right">Back</a></Box.Head> */}
                    <Box.Body>
                        <Alert />
                        <form onSubmit={this.handleSubmit} onReset={this.resetForm}>
                            {this.state.service_provider_type != 'nondhdoctor' ? <h4>Service Provider Details</h4> : <h4>Add Doctor</h4>}
                            <hr />

                            <div className="row form-group">
                                <div className="col-xs-4">
                                    <ControlLabel>Select Service Provider Type: <span>*</span></ControlLabel>
                                    <FormControl name="service_provider_type" componentClass="select" value={this.state.service_provider_type} onChange={this.handleChange}>
                                        <option value="">Select Provider Type</option>
                                        <option value="pharmacy">Pharmacy</option>
                                        <option value="radiology">Radiology</option>
                                        <option value="pathology">Pathology</option>
                                        <option value="nondhdoctor">Non-DH Doctor</option>
                                    </FormControl>
                                    {this.validator.message('service_provider_type', this.state.service_provider_type, 'required')}
                                </div>
                                <div className="col-xs-4">
                                    {this.state.service_provider_type != 'nondhdoctor' ? <ControlLabel>Service Provider Name: <span>*</span></ControlLabel> : <ControlLabel>Doctor Name: <span>*</span></ControlLabel>}
                                    <FormControl
                                        name='service_provider_name'
                                        type="text"
                                        value={this.state.service_provider_name}
                                        placeholder="Enter your first name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('service_provider_name', this.state.service_provider_name, 'required|string')}
                                </div>
                            </div>
                            <hr />

                            <h4>User Details</h4>
                            <hr />

                            <div className="row form-group">
                                <div className="col-xs-4">
                                    <ControlLabel>Select Name Title: <span>*</span></ControlLabel>
                                    <FormControl name="name_title" componentClass="select" value={this.state.name_title} onChange={this.handleChange}>
                                        <option value="">Select Title</option>
                                        {CONFIG.NAMETITLE.map((value) => {
                                            return <option value={value}>{value}</option>
                                        })}
                                    </FormControl>
                                    {this.validator.message('name_title', this.state.name_title, 'required')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>First Name: <span>*</span></ControlLabel>
                                    <FormControl
                                        name='first_name'
                                        type="text"
                                        value={this.state.first_name}
                                        placeholder="Enter your first name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('first_name', this.state.first_name, 'required|string')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Last Name: <span>*</span></ControlLabel>
                                    <FormControl
                                        name='last_name'
                                        type="text"
                                        value={this.state.last_name}
                                        placeholder="Enter your last name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('last_name', this.state.last_name, 'required|string')}
                                </div>
                            </div>

                            <div className="row form-group">

                                <div className="col-xs-4">
                                    <ControlLabel>Role: <span>*</span></ControlLabel>
                                    <FormControl name="user_role_slug" componentClass="select" value={this.state.user_role_slug} onChange={this.handleChange} disabled={true}>
                                        <option value="">User Role</option>
                                        <option value="pharmacist">Pharmacist</option>
                                        <option value="radiologist">Radiologist</option>
                                        <option value="pathologist">Pathologist</option>
                                        <option value="nondhdoctor">Non-DH Doctor</option>
                                    </FormControl>
                                    {this.validator.message('user_role_slug', this.state.user_role_slug, 'required')}
                                </div>
                                <div className="col-xs-4">
                                    <ControlLabel>Email: <span>*</span></ControlLabel>
                                    <FormControl
                                        name='email'
                                        type="email"
                                        value={this.state.email}
                                        placeholder="Enter your email"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('email', this.state.email, 'required|email')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Registration Number: *</ControlLabel>
                                    <FormControl
                                        name='license_number'
                                        type="text"
                                        value={this.state.license_number}
                                        placeholder="Enter your Registration Number"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('registration_number', this.state.license_number, 'required')}
                                </div>
                            </div>
                            <div className="row form-group">
                                {this.state.service_provider_type == 'nondhdoctor' ?
                                    <div className="col-xs-4">
                                        <ControlLabel>Gender: <span>*</span></ControlLabel>
                                        <FormControl name="user_gender" componentClass="select" value={this.state.user_gender} onChange={this.handleChange}>
                                            <option value="">User Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </FormControl>
                                    </div> : null}
                                {this.state.service_provider_type == 'nondhdoctor' &&
                                    <div className="col-xs-4">
                                        <ControlLabel>Contact Number: *</ControlLabel>
                                        <FormControl
                                            name='contact_number'
                                            type="text"
                                            maxLength="10"
                                            minLength="10"
                                            value={this.state.contact_number}
                                            placeholder="Enter your Contact Number"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('contact_number', this.state.contact_number, 'required')}
                                    </div>}
                                {this.state.service_provider_type == 'nondhdoctor' ?
                                    <div className="col-xs-4">
                                        <ControlLabel>Degree: <span>*</span></ControlLabel>
                                        <FormControl name="user_degree" componentClass="select" value={this.state.user_degree} onChange={this.handleChange}>
                                            <option value="">Select Degree</option>
                                            {CONFIG.DOCTORDEGREE.map((value) => {
                                                return <option value={value}>{value}</option>
                                            })}
                                            <option value="other_degree" >Other</option>
                                        </FormControl>
                                    </div> : null}
                            </div>
                            <div className="row form-group">
                                {this.state.service_provider_type == 'nondhdoctor' ?
                                    <div className="col-xs-3">
                                        <ControlLabel>Specialization: <span>*</span></ControlLabel>
                                        <FormControl name="user_specialization" componentClass="select" value={this.state.user_specialization} onChange={this.handleChange}>
                                            <option value="">Select Specialization</option>
                                            {CONFIG.SPECIALIZATION.map((value) => {
                                                return <option value={value}>{value}</option>
                                            })}
                                            <option value="other_specialization">Other</option>
                                        </FormControl>
                                    </div> : null}
                                {this.state.service_provider_type == 'nondhdoctor' ?
                                    <div className="col-xs-3">
                                        <ControlLabel>Max Users: </ControlLabel>
                                        <FormControl
                                            name='max_users'
                                            type="number"
                                            min="1"
                                            value="3"
                                            autoComplete="none" disabled={true}
                                        />

                                    </div> : null}
                                {this.state.service_provider_type == 'nondhdoctor' ?
                                    <div className="col-xs-3">
                                        <ControlLabel>Date of Birth:</ControlLabel>
                                        <FormControl
                                            name='date_of_birth'
                                            type="date"
                                            value={this.state.date_of_birth}
                                            onChange={this.handleChange}
                                            min='1800-01-01'
                                            max={moment().format('YYYY-MM-DD')}
                                        />
                                    </div> : null}
                                {this.state.service_provider_type == 'nondhdoctor' ?
                                    <div className="col-xs-3">
                                        <ControlLabel>Practice Name: <span>*</span></ControlLabel>
                                        <FormControl
                                            name='practice_name'
                                            type="text"
                                            value={this.state.practice_name}
                                            placeholder="Enter practice name"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                    </div> : null}
                            </div>
                            <div className="row form-group">
                                {this.state.service_provider_type == 'nondhdoctor' ?
                                    <div className="col-xs-12">
                                        <ControlLabel>Practice Address: </ControlLabel>
                                        <FormControl
                                            name='practice_address'
                                            type="text"
                                            value={this.state.practice_address}
                                            placeholder="Enter practice address"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                    </div> : null}
                            </div>
                            <hr />

                            <h4>Location Details</h4>
                            <hr />

                            <div className="row form-group">
                                <div className="col-xs-12">
                                    <ControlLabel>Location Name: <span>*</span></ControlLabel>
                                    <FormControl
                                        name='location_name'
                                        type="text"
                                        value={this.state.location_name}
                                        placeholder="Enter your location name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('location_name', this.state.location_name, 'required|string|max:100,string')}
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-xs-4">
                                    <ControlLabel>Address Line 1: *</ControlLabel>
                                    <FormControl
                                        name='address_line_1'
                                        type="text"
                                        value={this.state.address_line_1}
                                        placeholder="Enter your address line"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('address_line_1', this.state.address_line_1, 'required|string|max:255,string')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Address Line 2:</ControlLabel>
                                    <FormControl
                                        name='address_line_2'
                                        type="text"
                                        value={this.state.address_line_2}
                                        placeholder="Enter your address line"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('address_line_2', this.state.address_line_2, 'string|max:255,string')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Address Line 3:</ControlLabel>
                                    <FormControl
                                        name='address_line_3'
                                        type="text"
                                        value={this.state.address_line_3}
                                        placeholder="Enter your address line"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('address_line_3', this.state.address_line_3, 'string|max:255,string')}
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-xs-4">
                                    <ControlLabel>Parish: *</ControlLabel>
                                    <FormControl name="parish" componentClass="select" value={this.state.parish} onChange={this.handleChange} placeholder="Select Parish">
                                        <option value=''>Select Parish</option>
                                        {CONFIG.PARISH.map((value) => {
                                            return <option value={value}>{value}</option>
                                        })}
                                    </FormControl>
                                    {this.validator.message('parish', this.state.parish, 'required')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Community: *</ControlLabel>
                                    <CreatableSelect isClearable options={this.state.selectedParisCommunity} value={{ value: this.state.city, label: this.state.city }} onChange={(newValue) => this.setCommunity(newValue)} onCreateOption={this.createCommunity} />
                                    {this.validator.message('city', this.state.city, 'required|string|max:100,string')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Zip Code: *</ControlLabel>
                                    <FormControl
                                        name='pincode'
                                        type="text"
                                        value={this.state.pincode}
                                        placeholder="Enter your Zip Code"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('pincode', this.state.pincode, 'alpha_num|max:7')}
                                </div>
                            </div>

                            <FormGroup className="row" controlId="">
                                <Col sm={4} md={4}>
                                    <ButtonToolbar>
                                        <Button bsStyle="primary" type='submit'>Submit</Button>
                                        <button type="reset" className="btn btn-btn btn-danger" onClick={this.resetForm}><i className="fa fa-repeat"></i> Reset</button>
                                    </ButtonToolbar>
                                </Col>
                            </FormGroup>
                        </form>
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    admin: state.auth.admin,
});

const mapActionsToProps = ({
    createTrialAccount: TrialAccountActions.createTrialAccount,
});

export default connect(mapStateToProps, mapActionsToProps)(TrialAccountPage);
